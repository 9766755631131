<template>
  <div class="welcome-tasks">
    <Loader v-if="isLoading" loading full />

    <div class="header-container">
      <LogoShield />

      <h1>{{ $t('Getting started with FileCloud Server') }}</h1>
      <h3>
        {{
          $t(
            "Before you start your trial you'll need to complete the steps below"
          )
        }}
      </h3>
    </div>

    <StepIndicator :steps="steps" :current-step="currentStep" />

    <div class="task-list">
      <Task
        :title="$t('Download FileCloud Server')"
        :description="
          $t('Download the files and start the installation process')
        "
        :disabled="isStepDisabled('FCINSTALLER')"
      >
        <template #icon>
          <LaptopDownload />
        </template>

        <template #default>
          <DownloadButton :options="serverDownloads" @download="onDownload">
            <button
              class="btn btn-primary col"
              :disabled="isStepDisabled('FCINSTALLER')"
            >
              {{ $t('Download') }}
            </button>
          </DownloadButton>
        </template>
      </Task>

      <Task
        :title="$t('Activate your Trial')"
        :description="
          $t(
            'You will need to download your licence XML file to activate your FileCloud installation'
          )
        "
        :disabled="isStepDisabled('FCLICENSE')"
      >
        <template #icon>
          <LicensePaper />
        </template>

        <template #default>
          <a
            href="/core/?op=gettriallicense"
            target="_blank"
            class="btn btn-primary"
            :disabled="isStepDisabled('FCLICENSE')"
            @click="nextStep('FCLICENSE')"
          >
            {{ $t('Download License File') }}
          </a>
        </template>
      </Task>

      <Task
        :title="$t('Go to your Portal')"
        :description="$t('To manage your account and licenses')"
        :disabled="isStepDisabled('FCPORTAL')"
      >
        <template #icon>
          <PortalPencil />
        </template>

        <template #default>
          <button
            class="btn btn-primary"
            :disabled="isStepDisabled('FCPORTAL')"
            @click="nextStep('FCPORTAL')"
          >
            {{ $t('Go to the Portal') }}
          </button>
        </template>
      </Task>
    </div>
  </div>
</template>

<script>
import StepIndicator from '@/components/WelcomeTasks/StepIndicator';
import Task from '@/components/WelcomeTasks/Task';
import Loader from 'common/components/Loader.vue';

import LogoShield from '@/assets/logo-shield.svg';
import LaptopDownload from '@/assets/laptop-download.svg';
import LicensePaper from '@/assets/license-paper.svg';
import PortalPencil from '@/assets/portal-pencil.svg';
import DownloadButton from '@/components/Downloads/DownloadButton.vue';
import { downloadSections } from '@/constants/downloadsPage';

export default {
  name: 'WelcomeTasks',
  components: {
    LogoShield,
    StepIndicator,
    Task,
    DownloadButton,
    LaptopDownload,
    LicensePaper,
    PortalPencil,
    Loader,
  },
  data() {
    return {
      currentStep: 'FCINSTALLER',
      steps: ['FCINSTALLER', 'FCLICENSE', 'FCPORTAL'],
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.loading['sites/getSites'] ||
        this.$store.state.loading['core/init']
      );
    },
    completedSteps() {
      return this.$store.getters['core/getUserWelcomeTasksStatus'];
    },
    serverDownloads() {
      return (
        downloadSections.find((section) => section.id === 'server')
          ?.downloadOptions || []
      );
    },
    getCurrentStepIndex() {
      return this.steps.indexOf(this.currentStep);
    },
    getStepIndex() {
      return (step) => this.steps.indexOf(step);
    },
    isStepDisabled() {
      return (step) => this.getCurrentStepIndex < this.getStepIndex(step);
    },
  },
  async mounted() {
    const entries = Object.entries(this.completedSteps);
    for (let [taskName, value] of entries) {
      if (!value) {
        this.currentStep = taskName;
        break;
      }
    }
  },
  beforeDestroy() {
    this.$router.push('/sites/trial/server');
  },
  methods: {
    async nextStep(clickedStep) {
      const nextStep =
        this.steps?.[this.getCurrentStepIndex + 1] ||
        this.steps[this.steps.length - 1];

      if (this.getStepIndex(clickedStep) < this.getCurrentStepIndex) return;
      if (this.currentStep === 'FCPORTAL') {
        await this.$store.dispatch('core/init');
        await this.$store.dispatch('sites/getSites');
        return;
      }

      await this.$store.dispatch('core/updateWelcomeTask', {
        taskName: this.currentStep,
        status: true,
      });

      this.currentStep = nextStep;
    },
    onDownload() {
      this.nextStep('FCINSTALLER');
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-tasks {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40dvh;
    width: 100%;
    background-color: var(--bg-mid-medium-gray);

    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 20px;
      color: var(--color-primary);
      margin-top: 45px;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 21.78px;
      margin-top: 35px;
    }
  }

  .task-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 100px;
    width: 100%;
    margin-top: 50px;
  }
}
</style>
