<template>
  <div class="details-section">
    <Loader v-if="isFetching || !order" loading full />

    <form @submit.prevent="onClickPrimary">
      <h4 class="section-title">
        {{ $t('Billing Details') }}
      </h4>

      <div v-if="errors._message" class="alert alert-danger" role="alert">
        {{ $t(errors._message) }}
      </div>

      <div class="row">
        <InputBox
          id="company"
          v-model="billing.company"
          :label="$t('Company Name')"
          :errors="errors"
          disabled
          auto-select
        />
        <SelectBox
          id="country"
          v-model="billing.country"
          :disabled="payOnly || isPaid"
          :focus="true"
          :label="$t('Country')"
          :placeholder="$t('Select...')"
          :errors="errors"
        >
          <option
            v-for="country in countries"
            :key="country.name"
            :value="country.name"
          >
            {{ country.name }}
          </option>
        </SelectBox>
      </div>

      <div class="row">
        <InputBox
          id="address1"
          v-model="billing.address1"
          :focus="true"
          :disabled="payOnly || isPaid"
          :label="$t('Address')"
          :errors="errors"
          auto-select
        />
        <InputBox
          id="address2"
          v-model="billing.address2"
          :label="$t('Address 2')"
          :disabled="payOnly || isPaid"
          :errors="errors"
          optional
          auto-select
        />
      </div>

      <div class="row">
        <InputBox
          id="city"
          v-model="billing.city"
          :label="$t('City')"
          :disabled="payOnly || isPaid"
          :errors="errors"
          auto-select
        />
        <InputBox
          v-if="billing.country !== 'United States'"
          id="state"
          v-model="billing.state"
          :label="$t('State / Province')"
          :disabled="payOnly || isPaid"
          :errors="errors"
          auto-select
          @keydown="debounceSave()"
        />
        <SelectBox
          v-else
          id="state"
          v-model="billing.state"
          :disabled="payOnly || isPaid"
          :focus="true"
          :label="$t('State / Province')"
          :placeholder="$t('Select...')"
          :errors="errors"
        >
          <option
            v-for="state in states"
            :key="state.value"
            :value="state.value"
          >
            {{ state.name }}
          </option>
        </SelectBox>
        <InputBox
          id="zip"
          v-model="billing.zip"
          :label="$t('Zip / Postal Code')"
          :disabled="payOnly || isPaid"
          :errors="errors"
          auto-select
        />
      </div>

      <br>

      <h4 class="section-title">
        {{ $t('Ship To Address Details') }}
      </h4>

      <div class="row">
        <InputBox
            id="shipto-company"
            v-model="shipTo.company"
            :label="$t('Company Name')"
            disabled
        />

        <SelectBox
            id="shipto-country"
            v-model="shipTo.country"
            :label="$t('Country')"
            disabled
        >
          <option
              v-for="country in countries"
              :key="country.iso2"
              :value="country.iso2"
          >
            {{ country.name }}
          </option>
        </SelectBox>
      </div>

      <div class="row">
        <InputBox
            id="shipto-address1"
            v-model="shipTo.address1"
            :label="$t('Address')"
            disabled
        />
        <InputBox
            id="shipto-address2"
            v-model="shipTo.address2"
            :label="$t('Address 2')"
            disabled
        />
      </div>

      <div class="row">
        <InputBox
            id="shipto-city"
            v-model="shipTo.city"
            :label="$t('City')"
            disabled
        />
        <InputBox
            v-if="shipTo.country !== 'US'"
            id="shipto-state"
            v-model="shipTo.state"
            :label="$t('State / Province')"
            disabled
        />
        <SelectBox
            v-else
            id="shipto-state"
            v-model="shipTo.state"
            :label="$t('State / Province')"
            disabled
        >
          <option
              v-for="state in states"
              :key="state.value"
              :value="state.value"
          >
            {{ state.name }}
          </option>
        </SelectBox>
        <InputBox
            id="shipto-zip"
            v-model="shipTo.zip"
            :label="$t('Zip / Postal Code')"
            disabled
        />
      </div>

      <br>

      <h4 class="section-title">
        {{ $t('End User Details') }}
      </h4>

      <div class="row">
        <InputBox
            id="enduserdetails-companyname"
            v-model="billing.endusercompanyname"
            :label="$t('Company Name')"
        />
        <InputBox
            id="enduserdetails-contactname"
            v-model="billing.endusercontactname"
            :label="$t('Contact Name')"
        />
      </div>

    </form>
  </div>
</template>

<script>
import Loader from 'common/components/Loader';
import InputBox from '@/components/InputBox';
import SelectBox from '@/components/SelectBox';

import entities from '@/constants/entities';
import countries from '@/constants/countries';
import states from '@/constants/states';

import _ from 'lodash';

export default {
  name: 'BillingAddress',
  components: {
    Loader,
    InputBox,
    SelectBox,
  },
  props: {
    payOnly: {
      type: Boolean,
      default: false,
    },
    currentProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      billing: {
        country: '',
        state: '',
        vatnumber: '',
      },
      errors: {},
      states,
      countries,
      entities,
      shipTo: {},
    };
  },
  computed: {
    order() {
      return this.$store.state.checkout.singleOrder[
        this.currentProps.orderNumber
      ];
    },
    isPaid() {
      return this.order && typeof this.order.fullpaymentreceived === 'number'
        ? !!this.order.fullpaymentreceived
        : false;
    },
    isUnitedStates() {
      return this.billing.country === 'United States';
    },
    isFetching() {
      return (
        this.$store.state.loading['checkout/getOrder'] ||
        this.$store.state.loading['checkout/resetOrderTax'] ||
        this.$store.state.loading['checkout/updateBillingAddress']
      );
    },
  },
  watch: {
    'billing.country'(val, oldVal) {
      if ((val === 'United States' && oldVal) || oldVal === 'United States') {
        this.billing.state = '';
      }
    },
  },
  async mounted() {
    this.billing.company = this.normalizeData(this.order.company_name);
    this.billing.country = this.normalizeData(this.order.customer_country);
    this.billing.address1 = this.normalizeData(this.order.customer_address1);
    this.billing.address2 = this.normalizeData(this.order.customer_address2);
    this.billing.city = this.normalizeData(this.order.customer_city);
    this.billing.state = this.normalizeData(this.order.customer_state);
    this.billing.zip = this.normalizeData(this.order.customer_zip)?.toString();
    this.shipTo.company = this.normalizeData(this.order.ship_to_company_name);
    this.shipTo.country = this.normalizeData(this.order.ship_to_address_country);
    this.shipTo.address1 = this.normalizeData(this.order.ship_to_address_line1);
    this.shipTo.address2 = this.normalizeData(this.order.ship_to_address_line2);
    this.shipTo.city = this.normalizeData(this.order.ship_to_address_city);
    this.shipTo.state = this.normalizeData(this.order.ship_to_address_state);
    this.shipTo.zip = this.normalizeData(this.order.ship_to_address_zip);
    this.billing.endusercompanyname = this.normalizeData(this.order.end_user_company_name);
    this.billing.endusercontactname = this.normalizeData(this.order.end_user_contact_name);

    this.$emit('settings', {
      secondary:
        this.currentProps.previousStep === 'summary' ? 'Cancel' : 'Back',
      primary: this.currentProps.previousStep === 'summary' ? 'Save' : 'Next',
    });
  },
  methods: {
    debounceSave: _.debounce(async function () {
      this.save();
    }, 1000),
    normalizeData(data) {
      return typeof data !== 'object' ? data : '';
    },
    async onClickPrimary() {
      const response = await this.save();
      if (!response) return;

      this.$emit('changeStep', { step: 'summary' });
    },
    onClickSecondary() {
      if (this.currentProps.previousStep) {
        this.$emit('changeStep', { step: this.currentProps.previousStep });
      } else {
        this.$modal.hide('checkout');
      }
    },
    async save() {
      this.errors = {};

      const billingResponse = await this.$store.dispatch(
        'checkout/updateBillingAddress',
        {
          noRefetch: true,
          ordernumber: this.order.order_number,
          ...this.billing,
        }
      );
      if (!billingResponse.ok) {
        this.errors =
          typeof billingResponse.error === 'string'
            ? { _message: billingResponse.error }
            : billingResponse.error;
        return false;
      }
      return true;
    },
  },
};
</script>
