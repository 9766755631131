<template>
  <div id="app">
    <Loader title="Connecting to the server" :loading="!core.ready" full />
    <FullLayout
      v-if="core.ready && core.authenticated && $route.meta.layout === 'full'"
    />
    <MainLayout
      v-else-if="
        core.ready &&
        core.authenticated &&
        $route.name !== 'intro' &&
        $route.name !== 'quote'
      "
    />
    <GuestLayout v-else-if="core.ready" />
  </div>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import FullLayout from '@/layouts/Full.vue';
import GuestLayout from '@/layouts/Guest.vue';
import MainLayout from '@/layouts/Main.vue';

export default {
  name: 'App',
  components: {
    Loader,
    GuestLayout,
    MainLayout,
    FullLayout,
  },
  computed: {
    core() {
      return this.$store.state.core;
    },
    completedSteps() {
      return this.$store.getters['core/getUserWelcomeTasksStatus'];
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (!this.$store.state.core.ready) return;

        const isAuthenticated = !!this.$store.state.core.authenticated;

        // go back
        if (
          (isAuthenticated && to.meta?.unauthenticated) ||
          (!isAuthenticated && to.meta?.authenticated)
        ) {
          this.$router.push(
            from && from.path !== '/' ? from : isAuthenticated ? '/' : '/login'
          );
          return;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('core/init');
  },
};
</script>
