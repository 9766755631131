<template>
  <div
    v-if="!completedSteps.FCPORTAL || user.iscommunityedition"
    class="page-wrapper"
  >
    <Navbar />
    <div class="container-fluid">
      <div class="row content-wrapper">
        <Sidebar />
        <div class="col px-0">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <StartFreeTrial />
    <ProfileModal />
    <CheckoutModal />
    <TrialModal />
    <ExtendTrialModal />
    <ContactUsModal />
    <RenewInfoModal />
  </div>

  <WelcomeTasks v-else />
</template>

<script>
import Navbar from '@/components/Partials/Navbar.vue';
import Sidebar from '@/components/Partials/Sidebar.vue';
import ProfileModal from '@/components/Modals/Profile.vue';
import CheckoutModal from '@/components/Modals/Checkout.vue';
import TrialModal from '@/components/Modals/Trial.vue';
import ExtendTrialModal from '@/components/Modals/ExtendTrial.vue';
import ContactUsModal from '@/components/Modals/ContactUs.vue';
import StartFreeTrial from '@/components/Modals/StartFreeTrial.vue';
import RenewInfoModal from '@/components/Modals/RenewInfo.vue';
import WelcomeTasks from '@/components/WelcomeTasks.vue';

export default {
  name: 'MainLayout',
  components: {
    Navbar,
    Sidebar,
    ProfileModal,
    CheckoutModal,
    TrialModal,
    ExtendTrialModal,
    ContactUsModal,
    StartFreeTrial,
    RenewInfoModal,
    WelcomeTasks,
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
    completedSteps() {
      return this.$store.getters['core/getUserWelcomeTasksStatus'];
    },
  },
  mounted() {
    // if (
    //   this.user &&
    //   this.user.producttype === 10 &&
    //   this.user.istrialeligible === 1 &&
    //   this.user.istrialstarted === 0 &&
    //   this.user.iscommunityedition !== 1
    // ) {
    //   this.$modal.show('start-free-trial');
    // }
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
