<template>
  <router-view></router-view>
</template>

<script>
/* import ProfileModal from '@/components/Modals/Profile.vue';
import CheckoutModal from '@/components/Modals/Checkout.vue';
import TrialModal from '@/components/Modals/Trial.vue';
import ExtendTrialModal from '@/components/Modals/ExtendTrial.vue';
import ContactUsModal from '@/components/Modals/ContactUs.vue';
import StartFreeTrial from '@/components/Modals/StartFreeTrial.vue';
import RenewInfoModal from '@/components/Modals/RenewInfo.vue'; */

export default {
  name: 'MainLayout',
  components: {
    /* ProfileModal,
    CheckoutModal,
    TrialModal,
    ExtendTrialModal,
    ContactUsModal,
    StartFreeTrial,
    RenewInfoModal, */
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
  },
};
</script>
