<template>
  <div class="welcome-tasks-step-indicator">
    <span
      v-for="step in steps"
      :key="step"
      class="indicator-item"
      :class="{
        current: currentStep === step,
        completed: getStepIndex(step) < currentStepIndex,
      }"
    >
      <div class="indicator-number-wrapper">
        <span
          v-show="getStepIndex(step) < currentStepIndex"
          class="indicator-number"
        >
          <Icon name="check" />
        </span>
        <span
          v-show="!(getStepIndex(step) < currentStepIndex)"
          class="indicator-number"
          >{{ getStepIndex(step) + 1 }}</span
        >
      </div>
    </span>

    <span class="indicator-bar" :style="indicatorBarStyle"></span>
  </div>
</template>

<script>
import Icon from 'common/components/Icon.vue';

export default {
  name: 'StepIndicator',
  components: {
    Icon,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentStepIndex() {
      return this.steps.indexOf(this.currentStep);
    },
    getStepIndex() {
      return (step) => this.steps.indexOf(step);
    },
    indicatorBarStyle() {
      return {
        background: this.getIndicatorBarBackground,
      };
    },
    getIndicatorBarBackground() {
      let gradient = '';
      for (let i = 0; i < this.steps.length; i++) {
        if (i < this.currentStepIndex) {
          gradient += `var(--color-success) ${
            i * (100 / (this.steps.length - 1))
          }%, `;
        } else if (i === this.currentStepIndex) {
          gradient += `var(--color-primary) ${
            i * (100 / (this.steps.length - 1))
          }%, `;
        } else {
          gradient += `var(--color-primary--transparent) ${
            i * (100 / (this.steps.length - 1))
          }%, `;
        }
      }

      // remove the last comma and space
      gradient = gradient.slice(0, -2);

      return `linear-gradient(to right, ${gradient})`;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-primary: var(--color-primary);

.welcome-tasks-step-indicator {
  display: flex;
  justify-content: center;
  gap: 100px;
  position: relative;
  margin-top: -15px;

  .indicator-bar {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 2s ease-in-out;
  }

  .indicator-item {
    text-align: center;
    width: 255px;
    z-index: 1;
    transition: all 0.6s ease-in-out;

    &.current {
      .indicator-number {
        background-color: var(--color-primary);
      }
    }

    &.completed {
      .indicator-number {
        background-color: var(--color-success);
        color: var(--color-primary--foreground);
      }
    }

    .indicator-number-wrapper {
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      background-color: var(--bg-light);
      margin: 0 auto;
    }
    .indicator-number {
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      background-color: var(--color-primary--transparent);
      color: var(--color-primary--foreground);
      font-size: 16px;
      font-weight: 600;
      margin: 0 auto;
    }
  }
}
</style>
