<template>
  <div class="welcome-tasks__task" :class="{ disabled }">
    <div class="icon">
      <slot name="icon"></slot>
    </div>

    <h2>{{ title }}</h2>
    <p>{{ description }}</p>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Task',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-tasks__task {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 40px 100px auto;
  width: 100%;
  max-width: 255px;
  background-color: var(--bg-light);
  transition: opacity 0.6s ease-in-out;

  &.disabled {
    opacity: 0.2;
    user-select: none;
    pointer-events: none;
  }

  .icon {
    justify-self: center;
  }

  .icon,
  h2 {
    align-self: center;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    color: var(--color-primary);
    margin: 0;
  }

  p {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin: 0;
  }
}
</style>
